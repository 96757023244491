import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import AuthService from "@/service/AuthService";

const routes = [
    {
        path: '',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },    
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/worksheets',
                name: 'worksheets',
                component: () => import('@/views/worksheets/List.vue'),
            },
            {
                path: '/clients',
                name: 'clients',
                component: () => import('@/views/clients/List.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/users/List.vue'),
            },
            {
                path: '/machines',
                name: 'machines',
                component: () => import('@/views/machines/List.vue'),
            },
            {
                path: '/peripherals',
                name: 'peripherals',
                component: () => import('@/views/peripherals/List.vue'),
            },
            {
                path: '/product-prices',
                name: 'product-prices',
                component: () => import('@/views/productPrices/List.vue'),
            },
            {
                path: '/peripheral-histories',
                name: 'peripheral-histories',
                component: () => import('@/views/peripheralHistories/List.vue'),
            },
            {
                path: '/delivery-prices',
                name: 'delivery-prices',
                component: () => import('@/views/deliveryPrices/List.vue'),
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('@/views/generalSettings/List.vue'),
            },
        ]
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem('user');
    // const expire = localStorage.getItem('expire');

    if (!user && (to.fullPath !== '/')) {
        if (to.fullPath === '/forgotten-password') {
            next();
        } else {
            AuthService.logout();
            next('/');
        }
    } else {
        next();
    }
});

export default router;
