import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";
import jwt_decode from 'jwt-decode';

const API_URL = getEnvironment().API_URL;

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'login_check', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(jwt_decode(response.data.token)));
                    localStorage.setItem('token', JSON.stringify(response.data.token));

                    this.getData().then(
                        (data) => {
                            localStorage.setItem('userData', JSON.stringify(data.data));
                        }
                    )
                }

                return response.data.token;
            });
    }

    register(user) {
        return axios.post(API_URL + 'register', {
            name: user.name,
            email: user.email,
            password: user.password
        });
    }

    getData() {
        return axios.get(API_URL + 'user-data', { headers: authHeader() });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = "/";
    }
}

export default new AuthService();
